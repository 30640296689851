import { AnalyticsConfig, AppConfig } from '@neptune/config-core-domain';
import config from 'config';
import { isAppRunningInE2EContext } from 'common/e2e';
import { isOnPremiseDeployment } from 'common/deploymentModes';

import * as gtm from './gtm-inject';
import * as heap from './heap-analytics';
import * as ga from './google-analytics';
import * as intercom from './intercom';
import * as userflow from './userflow-analytics';

initAnalytics(config, isOnPremiseDeployment(), isAppRunningInE2EContext());

function initAnalytics(
  config: AppConfig & AnalyticsConfig,
  isEnterprise: boolean,
  isE2EContext: boolean,
) {
  if (isEnterprise || isE2EContext) {
    return;
  }

  if (config.appEnv === 'dev') {
    // eslint-disable-next-line
    console.warn(
      'It looks like you are in local development mode so that analytics script will not be attached',
    );
    return;
  }

  userflow.init(config.userflowId);
  gtm.init(config.isProduction);
  intercom.init(config.intercomId);
  heap.init(config.heapId);
  ga.init(config.isProduction);
}
